import { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import './BlogPost.css';
import remarkGfm from 'remark-gfm'
import { blogs, findBlogByUrlTitle } from './BlogConfig';

const BlogPost = () => {
  const [markdownContent, setMarkdownContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { title } = useParams();
  const location = useLocation();
  const blog = findBlogByUrlTitle(title);
  const markdownFile = blog.markdownFile

  useEffect(() => {
    const fetchMarkdown = async () => {
      if (!markdownFile) {
        setError('Blog post not found');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(markdownFile);
        if (!response.ok) throw new Error('Failed to load blog content');
        const text = await response.text();
        setMarkdownContent(text);
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading markdown:', error);
        setError('Failed to load blog content');
        setIsLoading(false);
      }
    };

    fetchMarkdown();
  }, [markdownFile]);

  if (isLoading) {
    return (
      <div className="blog-post-container">
        <div className="blog-post-loading">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="blog-post-container">
        <div className="blog-post-error">
          <p>{error}</p>
          <Link to="/blogs" className="back-link">Return to Blogs</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="blog-post-container">
      <article className="blog-post">
        <header className="blog-header">
          <h1 className="blog-title">{blog.title}</h1>
          <div className="blog-meta">
            <span>By Horus Labs Team</span>
            <span className="separator">|</span>
            <span>{blog.date.toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })}</span>
          </div>
        </header>
        <div className="blog-content">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdownContent}</ReactMarkdown>
        </div>
        <footer className="blog-footer">
          <Link to="/blogs" className="back-link">← Back to Blogs</Link>
        </footer>
      </article>
    </div>
  );
};

export default BlogPost;