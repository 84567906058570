import ActionButtons from './ActionButtons.js';
import './Nav.css';
import { useMediaQuery } from 'react-responsive';
import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Footer from './Footer.js';

//          <ul className="dropdown">
//            <li><FontAwesomeIcon icon={faSearch} /> Search</li>
//            <li><FontAwesomeIcon icon={faBook} /> Pricing</li>
//          </ul>


//<li className="nav-item">
//          <a href="/pricing">Pricing</a>
//        </li>
//        <li className="nav-item"><a href="#">Develop</a></li>
//        <li className="nav-item"><a href="#">Blogs</a></li>
//        <li className="nav-item"><a href="#">About Us</a></li>

const Navbar = () => {
  // Define breakpoints for responsive design
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  // hamburger menu
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <a href="/">
        <div className="logo">
          <img src="/logo-no-background.png" alt="Horus AI" />
        </div>
      </a>
      {!isMobile && (
          <ul className="nav-links">
            <li className="nav-item">
              <a href="/distillflow">Product</a>
            </li>
            <li className="nav-item">
              <a href="/blogs">Blogs</a>
            </li>
          </ul>
      )}
      {!isMobile && (
          <ActionButtons showGithub={true} />
      )}
      {isMobile && (
        <>
          <div className="hamburger-icon" onClick={toggleMenu}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div>
          {isOpen && (
            <div className="mobile-menu">
              <a href="/">
                <div className="logo">
                  <img src="/logo-no-background.png" alt="Horus AI" />
                </div>
              </a>
              <ul className="nav-links">
                <li className="nav-item">
                  <a href="/distillflow">Product</a>
                </li>
                <li className="nav-item">
                  <a href="/blogs">Blogs</a>
                </li>
              </ul>
              <ActionButtons showGithub={true} showGetInTouch={false} />
              <Footer />
            </div>
          )}
        </>
      )}
    </nav>
  );
};

export default Navbar;
