import neural from './neural_net.jpg'  // Adjust path as needed
import benchmarking_image from './benchmarking_image.jpg'  // Adjust path as needed

export const blogs = [
  {
    id: 1,
    title: "A primer to distillation",
    excerpt: "Learn about small models and distillation",
    image: neural,
    markdownFile: '/blogposts/primer-to-distillation.md',
    date: new Date(2025, 0, 26)
  },
    {
    id: 2,
    title: "Distillation Benchmarking",
    excerpt: "Benchmarking results on distillation",
    image: benchmarking_image,
    markdownFile: '/blogposts/distillation-benchmarking.md',
    date: new Date(2025, 1, 8)
  }
];

export const getUrlFriendlyTitle = (title) => {
  return title.toLowerCase().replace(/\s+/g, '-');
};

export const findBlogByUrlTitle = (urlTitle) => {
  return blogs.find(blog => getUrlFriendlyTitle(blog.title) === urlTitle);
};